<template>
  <div>
    <div class="pending-work-row">
      <div
        class="flex-1 overflow-ellipsis overflow-x-hidden py-2 px-3 cursor-default"
        :style="{ backgroundColor }"
      >
        <span class="whitespace-nowrap uppercase font-nova-semibold text-white">
          {{ work.client }}
        </span>
      </div>

      <!-- Select Work -->
      <div v-if="!isSelected" class="bg-white">
        <div class="flex items-center h-full cursor-pointer" @click="$emit('select', work.id)">
          <ArrowDownIcon class="pl-3 pr-2 h-5" />
        </div>
      </div>

      <!-- Menu desplegable -->
      <div v-else class="bg-white">
        <div
          v-if="!showMenu"
          class="flex items-center h-full cursor-pointer"
          @click="showMenu = !showMenu"
        >
          <EllipsisVIcon class="px-3 h-5" />
        </div>
        <div v-else class="relative h-full flex gap-1 z-20">
          <div
            class="icon-container bg-blue-500 text-white"
            @click="setWorkToBeReassigned(work.id)"
          >
            <BackIcon />
          </div>
          <div class="icon-container bg-red-500" @click="attemptDeleteWork(work)">
            <TrashIcon />
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="isSelected" class="work-info bg-white mt-1 pl-4 pr-10 text-sm font-nova-bold"> -->
    <div
      class="work-info bg-white pl-4 pr-10 text-sm font-nova-bold"
      :class="{ folded: !isSelected }"
    >
      <div class="border-b border-black py-3">{{ work.keyword + '/' + work.keyword2 }}</div>
      <div class="flex items-center gap-4 py-3">
        <DificultadItem :level="work.idDificultyLevel" />
        <span class="uppercase"> {{ deadlineDisplay }} </span>
      </div>
    </div>

    <!-- Modals -->
    <BlackModal
      v-if="showModalReassignmentComplete"
      text="ingreso correcto"
      @confirm="resetState"
    />
    <BlackModal
      v-if="showModalConfirmDelete"
      text="estás seguro de eliminar trabajo"
      :actions="2"
      :success="false"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
      @close="showModalConfirmDelete = false"
    />
    <BlackModal
      v-if="showModalDeleteCompleted"
      text="eliminado con éxito"
      @confirm="closeDeleteCompletedModal"
    />

    <div v-if="showModalReassignWork" class="reassign-work-modal">
      <div class="inner bg-black flex flex-col items-center rounded-xl text-white py-4 px-4">
        <div class="flex items-center text-xl px-2 pb-2 py-4 mb-8" @click="closeModalReassignWork">
          <Chevron class="w-3 mr-6 rotate-180deg" />
          <span class="font-nova-semibold"> VOLVER </span>
        </div>
        <h3 class="heading mb-8">
          TRASLADAR <br />
          TRABAJO
        </h3>
        <div class="employees w-full">
          <div v-for="employee in employees" :key="employee.id" class="employee mb-1">
            <ArrowButton
              :text="employee.name"
              background="#ccc"
              color="#000"
              @onClick="reassignWork(employee.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BackIcon from '@/components/shared/icons/BackIcon.vue'
import Chevron from '@/components/shared/icons/Chevron.vue'
import TrashIcon from '@/components/shared/icons/TrashIcon.vue'
import EllipsisVIcon from '@/components/shared/icons/EllipsisVIcon.vue'
import ArrowDownIcon from '@/components/shared/icons/ArrowDownIcon.vue'
import BlackModal from '@/components/shared/BlackModal.vue'
import ArrowButton from '@/components/shared/ArrowButton.vue'
import DificultadItem from '@/components/dificulty/DificultadItem.vue'

export default {
  name: 'PendingWorkRow',
  components: {
    BlackModal,
    BackIcon,
    Chevron,
    ArrowButton,
    TrashIcon,
    EllipsisVIcon,
    ArrowDownIcon,
    DificultadItem
  },
  props: {
    work: { type: Object, default: null },
    isSelected: { type: Boolean, default: false }
  },
  data() {
    return {
      showMenu: false,

      workToBeDeleted: null,
      showModalConfirmDelete: false,
      showModalDeleteCompleted: false,

      workBeignReassigned: null,
      showModalReassignWork: false,
      showModalReassignmentComplete: false,

      backgroundColor: '#7fbd42',
      deadlineDisplay: null
    }
  },

  computed: {
    ...mapGetters({ employees: 'employees/employees' })
  },

  created() {
    const deadline = new Date(this.work.deadline)
    const today = new Date()

    const formatDeadline = deadline.toLocaleDateString('es-ES', {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    })
    this.deadlineDisplay = formatDeadline.replace(',', '').replace('de', '')

    if (
      deadline.getDate() == today.getDate() &&
      deadline.getMonth() == today.getMonth() &&
      deadline.getFullYear() == today.getFullYear()
    ) {
      // deadline is today
      this.backgroundColor = '#ff8f00'
    } else if (deadline < today) {
      // deadline is in the past
      this.backgroundColor = '#f00'
    } else {
      // deadline is in the future
      this.backgroundColor = '#7fbd42'
    }
  },

  methods: {
    ...mapActions({
      assignUserToWork: 'pendientes/assignUserToWork',
      deleteWork: 'works/deleteWork'
    }),

    setWorkToBeReassigned(workId) {
      this.workBeignReassigned = workId
      this.showModalReassignWork = true
    },

    async reassignWork(userId) {
      const { success } = await this.assignUserToWork({
        UserId: userId,
        WorkId: this.workBeignReassigned
      })

      if (success) {
        this.closeModalReassignWork()
        this.showModalReassignmentComplete = true
      }
    },

    closeModalReassignWork() {
      this.workBeignReassigned = null
      this.showModalReassignWork = false
    },

    resetState() {
      this.workBeignReassigned = null
      this.showModalReassignmentComplete = false
      this.$emit('refresh')
    },

    attemptDeleteWork(work) {
      this.workToBeDeleted = work
      this.showMenu = false
      this.showModalConfirmDelete = true
    },

    cancelDelete() {
      this.workToBeDeleted = null
      this.showModalConfirmDelete = false
    },

    async confirmDelete() {
      const { success } = await this.deleteWork(this.workToBeDeleted.id)
      if (success) {
        this.showModalDeleteCompleted = true
      }
    },

    closeDeleteCompletedModal() {
      this.showModalDeleteCompleted = false
      this.resetState()
    }
  }
}
</script>

<style scoped>
.pending-work-row {
  @apply flex justify-between gap-1 text-lg;
}
.icon-container {
  @apply flex items-center justify-center px-3 w-12 cursor-pointer;
}

.work-info {
  height: 105px;
  transition: height 350ms ease-out;
  overflow-y: hidden;
  margin-top: 0.25rem;
}

.work-info.folded {
  transition: height 150ms ease-out;
  height: 0;
  margin-top: 0;
}

.reassign-work-modal {
  @apply fixed top-0 left-0 w-full h-full bg-transparent flex items-center justify-center z-20;
}
.inner {
  max-height: 90vh;
  overflow-y: auto;
  width: 90vw;
  font-family: WestmountExtraBold;
}
.heading {
  @apply uppercase text-center;
  font-size: 1.75rem;
  line-height: 1;
  letter-spacing: 1px;
}
.rotate-180deg {
  transform: rotate(180deg);
}
</style>

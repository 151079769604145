<template>
  <div class="flex flex-col gap-1 bg-white">
    <ArrowButton
      v-for="recurso in usersWithPendingWorks"
      :key="recurso.id"
      :text="recurso.name"
      color="#000"
      background="#ccc"
      rotate="-90deg"
      @onClick="selectRecurso(recurso)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ArrowButton from '@/components/shared/ArrowButton.vue'

export default {
  name: 'SeleccionRecursoPendientes',
  components: { ArrowButton },

  computed: {
    ...mapGetters({ usersWithPendingWorks: 'employees/usersWithPendingWorks' })
  },

  mounted() {
    this.getUsersWithPendingWorks()
  },

  methods: {
    ...mapActions({
      getUsersWithPendingWorks: 'employees/getUsersWithPendingWorks'
    }),
    ...mapMutations({
      setRecursoPendientes: 'pendientes/setRecurso'
    }),

    selectRecurso(recurso) {
      this.setRecursoPendientes(recurso)
      this.$emit('recursoSet', recurso)
    }
  }
}
</script>

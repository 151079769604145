<template>
  <div class="flex flex-col gap-1">
    <BackButton
      v-if="recurso"
      :on-click-emit="true"
      :text="recurso.name"
      class="mb-1"
      @onClick="resetState"
    />
    <BackButton v-else class="mb-1" :route="{ name: 'Director' }" />

    <SeleccionRecursoPendientes v-if="!recurso" class="mb-1" @recursoSet="recursoSet" />

    <div v-if="!gettingUserPendingWorks">
      <div v-if="userPendingWorks.length">
        <div v-for="pendingWork in userPendingWorks" :key="pendingWork.id">
          <PendingWorkRow
            :work="pendingWork"
            :is-selected="pendingWork.id === selectedWork"
            class="mb-1"
            @select="setSelected"
            @refresh="refresh"
          />
        </div>
      </div>
    </div>
    <div v-if="currentEmployee && gettingUserPendingWorks" class="py-2 px-1">cargando...</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import BackButton from '@/components/shared/BackButton.vue'
import SeleccionRecursoPendientes from '@/components/director/pendientes/SeleccionRecursoPendientes.vue'
import PendingWorkRow from '@/components/director/pendientes/PendingWorkRow.vue'

export default {
  name: 'Pendientes',
  components: {
    BackButton,
    SeleccionRecursoPendientes,
    PendingWorkRow
  },
  data() {
    return {
      loading: false,
      gettingUserPendingWorks: true,
      currentEmployee: null,
      selectedWork: null
    }
  },
  computed: {
    ...mapGetters({
      recurso: 'pendientes/recurso',
      userPendingWorks: 'pendientes/userPendingWorks'
    })
  },

  created() {
    this.getEmployees()
  },

  methods: {
    ...mapActions({
      getUserPendingWorks: 'pendientes/getUserPendingWorks',
      getEmployees: 'employees/getEmployees'
    }),
    ...mapMutations({
      setRecurso: 'pendientes/setRecurso',
      setUserPendingWorks: 'pendientes/setUserPendingWorks'
    }),

    async recursoSet(recurso) {
      this.gettingUserPendingWorks = true
      this.currentEmployee = recurso
      await this.getUserPendingWorks(recurso.id)
      this.gettingUserPendingWorks = false
    },

    resetState() {
      this.setRecurso(null)
      this.setUserPendingWorks(null)
      this.gettingUserPendingWorks = true
      this.currentEmployee = null
      this.selectedWork = null
    },

    refresh() {
      this.recursoSet(this.currentEmployee)
    },

    setSelected(workId) {
      this.selectedWork = workId
    }
  }
}
</script>
